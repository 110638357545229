<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs">{{title}}</v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <span v-if="loading_clients" class="body-2">Chargement des clients...</span>
        <v-autocomplete v-model="clientId" :items="clients" item-text="name" item-value="id" :disabled="createDirectly" label="Clients (Logiciel)"></v-autocomplete>
        <v-checkbox v-model="createDirectly" label="Créer directement le bénéficiaire"></v-checkbox>
        <v-row no-gutters>
          <v-col cols="12">
            <span>
              <strong>Maîtrise d'oeuvre</strong><br>
              Total : {{dossier.maitrise_oeuvre.toFixed(2)}} € <br>
              Facturé : {{facture_moe.toFixed(2)}} € <br>
              Reste à facturer : {{reste_moe.toFixed(2)}} € <br>
            </span>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="addMoe" label="Facturer la MOE"></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="addMoe40" label="Facturer la MOE (40%)"></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="addMoe60" label="Facturer la MOE (60%)"></v-checkbox>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span>
              <strong>AISFT</strong><br>
              Total : {{ aisft_total.toFixed(2) }} € <br>
              Facturé : {{facture_aisft.toFixed(2)}} € <br>
              Reste à facturer : {{reste_aisft.toFixed(2)}} € <br>
            </span>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="addAisftArrete" label="Facturer l'AISFT - Arrêté - 4000 €"></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="addAisftChantier" label="Facturer l'AISFT - Ouverture Chantier - 1000 €"></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="addAisftOperation" label="Facturer l'AISFT - Opération - 1000 €"></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="addAisftNotif" label="Facturer l'AISFT - Notification CTM - 2500 €"></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="addAisftChantierSoliha" label="Facturer l'AISFT - Ouverture Chantier SOLIHA - 3000 €"></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="addAisftFinChantierSoliha" label="Facturer l'AISFT - Livraison Chantier SOLIHA - 3000 €"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :disabled="creating" @click="generateFacture">Générer</v-btn>
        <v-progress-circular v-if="creating" indeterminate color="primary"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {ReglementRepository} from "@/repository/reglement";

export default {
  name: 'HonoraireForm',
  props: {
    dossier: Object,
    title: {
      type: String,
      default: "Ajouter une facture",
    },
    preloadedClients: {
      type: Boolean,
      default: false,
    }
  },
  async mounted() {
    if (!this.preloadedClients) {
      this.loading_clients = true;
      await this.$store.dispatch("clients/fetchClients");
      this.loading_clients = false;
    }
  },
  data() {
    return {
      createDirectly: false,
      addMoe: false,
      addMoe40: false,
      addMoe60: false,
      addAisftArrete: false,
      addAisftChantier: false,
      addAisftOperation: false,
      addAisftChantierSoliha: false,
      addAisftFinChantierSoliha: false,
      addAisftNotif: false,
      creating: false,
      dialog: false,
      clientId: false,
      loading_clients: false,
    };
  },
  methods: {
    async generateFacture() {
      this.creating = true;
      let payload = {
        client_id: this.clientId,
        create_client_directly: this.createDirectly,
        moe: this.addMoe,
        moe40: this.addMoe40,
        moe60: this.addMoe60,
        aisft_arrete: this.addAisftArrete,
        aisft_chantier: this.addAisftChantier,
        aisft_operation: this.addAisftOperation,
        aisft_notification: this.addAisftNotif,
        aisft_chantier_soliha: this.addAisftChantierSoliha,
        aisft_fin_chantier_soliha: this.addAisftFinChantierSoliha,
        ccas: this.addCCAS,
        montant_ccas: this.montantCCAS,
        dossier: this.dossier.id,
      }
      var repo = new ReglementRepository();
      let result = await repo.createFactureHonoraires(payload);
      if(result === "SUCCESS") {
        this.$store.dispatch("annonce/annonceSuccess", "Les honoraires ont bien étés facturés.");
        this.$emit("reload");
      }
      this.creating = false;
      this.dialog = false;

    },
    find_saisie(source) {
      return this.dossier.saisies?.find(saisie => saisie.source === source);
    },
  },
  computed: {
    clients() {
      return this.$store.getters["clients/clients"];
    },
    saisie_moe() {
      return this.find_saisie("MOE");
    },
    facture_moe() {
      return this.saisie_moe ? this.saisie_moe.total : 0;
    },
    reste_moe() {
      return this.dossier.maitrise_oeuvre - this.facture_moe;
    },
    saisie_aisft() {
      return this.find_saisie("AISFT");
    },
    facture_aisft() {
      return this.saisie_aisft ? this.saisie_aisft.total : 0;
    },
    montants_aisft() {
      return [
        {value: 1000, label: "1000 €"},
        {value: 1000, label: "1000 €"},
      ]
    },
    aisft_total() {
      return parseFloat(this.dossier.honoraires.find(honoraire => honoraire.origine === "Mission AISFT")?.montant) ?? 8500;
    },
    reste_aisft() {
      return this.aisft_total - this.facture_aisft;
    },
  },
}
</script>